import React from "react"
import Screen1 from "./../assets/png/365BOX-Screen-2.png"

const Section3 = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row md:p-16 md:gap-16 gap-8 p-8 max-w-screen-xl mx-auto  items-center justify-center">
        <div className="basis-1/2 space-y-2">
          <h2 className="text-xl">
            Personalisierte Inhalte auf Ihrer Startseite.
          </h2>
          <p className="text-xs">
            Für Endandwender konzipiert. Jeder Benutzer entscheidet selbst
            welche Inhalte er auf seiner persönlichen Startseite zu sehen
            bekommt. Selbstverständlich nur wenn Sie das möchten.
          </p>
        </div>
        <div className="basis-1/2">
          <img className="h-72 md:ml-auto" src={Screen1} alt="" />
        </div>
      </div>
      <div className="bg-secondary text-background font-bold p-8 md:p-32 text-center">
        <h2 className="text-xl text-background">
          Wie kann Ihr 365BOX Intranet aussehen?
        </h2>
        <h1 className="text-3xl text-background">Finden Sie es heraus</h1>
      </div>
    </div>
  )
}

export default Section3
