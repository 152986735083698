import React from "react"
import { ReactSVG } from "react-svg"
import Planning from "./../assets/svg/icon-plan.svg"
import Adopt from "./../assets/svg/icon-adopt.svg"
import Implement from "./../assets/svg/icon-implement.svg"
const Section2 = () => {
  return (
    <div className="bg-background ">
      <div className=" max-w-screen-xl mx-auto  px-8 py-8  md:p-16">
        <div className="text-center text-lg">
          Wir unterstützen Sie bei der Einführung Ihres Intranets
        </div>
        <div className="flex flex-col sm:flex-row gap-8 pt-8">
          {/*  ------------------------------------------------- */}
          <div className="space-y-2">
            <div className="flex items-end gap-2">
              <div className="h-12 w-12 flex justify-center items-center bg-secondary rounded">
                <ReactSVG className="m-auto" src={Planning} />
              </div>
              <h3 className="font-bold text-xs">Planung</h3>
            </div>
            <p className="text-xs">
              Es ist uns wichtig, Ihre Anforderungen genau zu verstehen. Wir
              hören zu und planen mit Ihnen gemeinsam die nächsten Schritte.
            </p>
          </div>
          {/* ---------------------------------------------------- */}

          {/*  ------------------------------------------------- */}
          <div className="space-y-2">
            <div className="flex items-end gap-2">
              <div className="h-12 w-12 flex justify-center items-center bg-secondary rounded">
                <ReactSVG className="m-auto" src={Implement} />
              </div>
              <h3 className="font-bold text-xs">Implementierung</h3>
            </div>
            <p className="text-xs">
              Wir installieren und konfigurieren mit Ihnen gemeinsam Ihr neues
              365BOX Intranet und passen es nach Ihren Wünschen an.
            </p>
          </div>
          {/* ---------------------------------------------------- */}

          {/*  ------------------------------------------------- */}
          <div className="space-y-2">
            <div className="flex items-end gap-2">
              <div className="h-12 w-12 flex justify-center items-center bg-secondary rounded">
                <ReactSVG className="m-auto" src={Adopt} />
              </div>
              <h3 className="font-bold text-xs">User Adoption</h3>
            </div>
            <p className="text-xs">
              Damit Ihr Intranetlaunch zum vollen Erfolg wird, begleiten wir Sie
              im Bereich User Adoption und etablieren das Intranet zum festen
              Bestandteil Ihrer Firmenkultur.
            </p>
          </div>
          {/* ---------------------------------------------------- */}
        </div>
      </div>
    </div>
  )
}

export default Section2
