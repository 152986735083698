import { Link } from "gatsby"
import React, { useState } from "react"
import Player from "./../assets/png/Player.png"
import PlayButton from "./../assets/svg/PlayButton.svg"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"

const Hero = () => {
  const [open, setOpen] = useState(false)
  const onOpenModal = () => setOpen(true)
  const onCloseModal = () => setOpen(false)

  return (
    <div className="hero-bg">
      <div className="flex flex-col md:flex-row md:py-16 md:px-16 mt-16 gap-4 justify-between max-w-screen-xl mx-auto items-center hero-bg">
        <Modal
          open={open}
          classNames={{
            overlay: "customOverlay",
            modal: "customModal",
          }}
          onClose={onCloseModal}
          center
        >
          <iframe
            className="aspect-video w-full"
            src="https://www.youtube.com/embed/RaTpreA0v7Q"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Modal>
        <div className="space-y-4 sm:px-12 px-8 py-8">
          <h2 className="text-xl">
            Out-of-the-box Intranet für SharePoint Online
          </h2>
          <h1 className="text-3xl text-bold">365BOX Intranet</h1>
          <p className="text-xs text-bold">
            Next Level Microsoft 365 Employee Experience Platform auf Basis von
            SharePoint Online.{" "}
          </p>
          <div className="gap-3 flex flex-wrap">
            <Link
              className="py-2 px-4 bg-highlight text-background text bold"
              to={"/demo"}>
              Demo Buchen
            </Link>
            <Link
              className="py-2 px-4 border-highlight border-2 text-highlight"
              to={"#Pricing"}>
              Preise ansehen
            </Link>
          </div>
        </div>
        <div className="mb-8 md:mb-0 px-8 relative">
          <button onClick={onOpenModal} onKeyDown={onOpenModal}>
            <img alt="365BOX Player" src={Player} />
            <img className="absolute z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 animate-pulse" alt="Play" src={PlayButton} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Hero
