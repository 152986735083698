import React from "react"
import { ReactSVG } from "react-svg"
import small from "./../assets/svg/icon-user-small.svg"
import medium from "./../assets/svg/icon-user-medium.svg"
import large from "./../assets/svg/icon-user-large.svg"
import xlarge from "./../assets/svg/icon-user-xlarge.svg"
import Logo from "./../assets/svg/365BOX-Icon-Colorized 2.svg"
import CheckIcon from "./../assets/svg/icon-checkmark2.svg"

const PricingSection = () => {
  return (
    <div id="Pricing">
      <div className="text-center md:py-8 space-y-8">
        <div className="mt-8">Preise</div>
        <div className="flex flex-col md:flex-row gap-4 px-16 sm:px-36 justify-around max-w-screen-xl mx-auto">
          <PriceCard
            icon={small}
            name={"SMALL"}
            users={"1 - 299 User"}
            price={"€1500"}
            update={"€500"}
          />
          <PriceCard
            icon={medium}
            name={"MEDIUM"}
            users={"300 - 999 User"}
            price={"€3000"}
            update={"€1000"}
          />
          <PriceCard
            icon={large}
            name={"LARGE"}
            users={"1000 - 4000 User"}
            price={"€6000"}
            update={"€2000"}
          />
          <PriceCard
            icon={xlarge}
            name={"XTRA LARGE"}
            users={">4000 User"}
            price={"€8000"}
            update={"€2500"}
          />
        </div>
        <p className="text-xs px-8 pb-8">
          Alle Preise inkludieren die Lizenz für einen Microsoft 365 Tenant
          inklusive einem Jahr kostenlose Updates.<br/> *Update Cycles werden nur bei unveränderten Produktverkäufen angeboten. 
        </p>
      </div>
      {/* -------------------------------------------------------------------------- */}

      <div className="bg-secondary">
        <div className="flex flex-col md:flex-row gap-8 py-12 md:px-16 px-8 items-center justify-center max-w-screen-xl mx-auto">
          <div className="md:basis-1/2">
            <img alt="" src={Logo} />
          </div>
          <div className="md:basis-1/2 space-y-2">
            <h2 className="text-background text-xl">365BOX</h2>
            <div className="text-background text-3xl pb-4">
              SharePoint Online
            </div>
            <p className="text-background text-xs pb-4">
              SharePoint Online und 365BOX legen den Grundstein für Ihre
              Employee Experience Plattform.
            </p>
            {/* ---------List-------------------*/}
            <ListItem text={"In wenigen Minuten installiert"} />
            <ListItem text={"Einfach zu konfigurieren"} />
            <ListItem text={"Perfekte Integration in Ihre SharePoint Online Umgebung"} />
            <ListItem text={"Personalisierbar"} />{" "}
            <ListItem text={"Nach Ihren Wünschen erweiterbar"} />
          </div>
        </div>
      </div>
    </div>
  )
}

export default PricingSection

const PriceCard = ({ name, users, icon, price, update }) => {
  return (
    <div className="basis-1/4 border-border border-2 text-center py-4 px-8">
      <div className="inline-block mx-auto">
        <ReactSVG src={icon} />
      </div>
      <div className="font-bold">{name}</div>
      <div>{users}</div>
      <div className="mt-2 text-highlight text-2xl">{price}</div>
      <strong className="block mt-24">Update Cycle*</strong>
      <p>{update}/Jahr</p>
    </div>
  )
}

const ListItem = ({ text }) => {
  return (
    <div className="flex items-start gap-4">
      <div className="bg-highlight rounded-full p-1 flex justify-center items-center">
        <ReactSVG src={CheckIcon} />
      </div>
      <p className="text-background text-xs font-bold">{text}</p>
    </div>
  )
}
