import React from "react"
import Screen1 from "./../assets/png/365BOX-Screen-1.png"
import Icon from "./../assets/png/SharePoint-Logo.png"

const Section1 = () => {
  return (
    <div>
      <div className="flex flex-col md:flex-row md:px-24 px-8 gap-12 py-16 max-w-screen-xl mx-auto items-center justify-center">
        <div className="basis-1/2">
          <img className="h-72  " src={Screen1} alt="" />
        </div>
        <div className="basis-1/2 space-y-2 ">
          <h2 className="text-bold text-xl">
            Holen Sie mehr aus ihrem Intranet
          </h2>
          <p className="text-xs">
            365BOX erweitert die Möglichkeiten von SharePoint Online um
            zahlreiche Funktionen und Darstellungsmöglichkeiten.
          </p>
          <p className="text-xs">
            Einfach zu konfigurierende Komponenten lassen sich mit wenigen
            Einstellungen flexibel an Ihre Wünsche und Vorgaben anpassen.{" "}
          </p>
          <div className="pt-6">
            <img className="h-24 mx-auto" src={Icon} alt="" />
          </div>
        </div>
      </div>
      {/*  ---------------------------------------------------------------------------------------- */}
      <div className="text-background bg-primary">
        <div className="flex flex-col md:flex-row md:px-16 lg:px-32 md:items-center max-w-screen-xl mx-auto md:py-32 gap-8 py-8 px-8 ">
          <div className="basis-1/2   ">
            <h3 className="text-lg text-background">Eigenentwicklung Adé</h3>
            <h2 className="text-3xl text-background">
              365BOX nach ihren Wünschen!
            </h2>
          </div>
          <p className="basis-1/2 text-xs text-background ">
            365BOX dient als Grundlage für Ihre neue Employee Experience
            Plattform auf Basis von SharePoint Online. Gerne arbeiten wir
            individuellen Änderungswünsche schnell flexibel in das Produkt ein.
          </p>
        </div>
      </div>
    </div>
  )
}

export default Section1
