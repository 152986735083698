import { Link } from "gatsby"
import React from "react"
import { ReactSVG } from "react-svg"
import Logo from "./../assets/svg/365BOX-Icon-Colorized.svg"

const InterestedSection = () => {
  return (
    <div className="space-y-16 py-8 md:py-24">
      <div className="space-y-4 text-center">
        <p>Interessiert?</p>
        <p>Jetzt 365BOX Demo buchen oder Preise ansehen</p>
        <div className="space-x-3 pt-3">
          <Link
            className="py-2 px-4 bg-highlight text-background text bold"
            to={"/demo"}>
            Demo Buchen
          </Link>
          <Link
            className="py-2 px-4 border-highlight border-2 text-highlight"
            to={"#Pricing"}>
            Preise ansehen
          </Link>
        </div>
      </div>
      {/* ---------------------------------- */}
      <div className="flex flex-col md:flex-row justify-center items-center px-16 md:px-32 md:gap-16 gap-8">
        <div className="border-2 border-border p-4 max-w-xs text-center">
          <div>
            <ReactSVG className="flex justify-center" src={Logo} />
          </div>
          <h3 className="text-highlight">Out-Of-The-Box</h3>
          <p>
            Sparen Sie Geld durch den Einsatz flexibler Komponenten ganz ohne Entwicklung.
          </p>
        </div>
        {/* ----------------------------------------------------------- */}
        <div className="border-2 border-border p-4 max-w-xs text-center">
          <div>
            <ReactSVG className="flex justify-center" src={Logo} />
          </div>
          <h3 className="text-highlight">Flexibel erweiterbar</h3>
          <p>
            Out of the Box mit der Option auf mehr. Wir passen 365BOX auf Ihre Wünsche an.
          </p>
        </div>
      </div>
    </div>
  )
}

export default InterestedSection
