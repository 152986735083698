import * as React from "react"

import Layout from "../components/layout"
import Hero from "../components/Hero"
import Section1 from "../components/Section1"
import Section2 from "../components/Section2"
import Section3 from "../components/Section3"
import InterestedSection from "../components/InterestedSection"
import InsideTheBoxSection from "../components/InsideTheBoxSection"
import PricingSection from "../components/PricingSection"
import BroschureSection from "../components/Broschure"
import ContactForm from "../components/ContactForm"

const Home = () => (
  <Layout>
    <Hero />
    <Section1 />
    <Section2 />
    <Section3 />
    <InterestedSection />
    <InsideTheBoxSection />
    <PricingSection />
    <BroschureSection />
    <ContactForm />
  </Layout>
)

export default Home
