import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"

const ContactForm = () => {
  const [personName, setPersonName] = useState("")
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [response, setResponse] = useState(null)

  const handleSubmit = async e => {
    e.preventDefault()
    const res = await addToMailchimp(email, { personName, message })
    console.log(res)
    setMessage("")
    setEmail("")
    setPersonName("")
    setResponse(res)
  }
  return (
    <div id="Contact" className="text-center px-8 py-8 space-y-8">
      <div>Kontakt</div>
      <form
        onSubmit={handleSubmit}
        className="flex md:w-[500px] md:mx-auto gap-4 flex-col"
      >
        <input
          vlaue={personName}
          onChange={e => setPersonName(e.target.value)}
          placeholder="Name"
          className="bg-background p-2 focus:outline-highlight"
          type="text"
        />
        <input
          vlaue={email}
          onChange={e => setEmail(e.target.value)}
          placeholder="Email"
          className="bg-background p-2 focus:outline-highlight"
          type="email"
        />
        <textarea
          vlaue={message}
          onChange={e => setMessage(e.target.value)}
          placeholder="Nachricht"
          className="bg-background p-2 h-24 focus:outline-highlight"
          type="text"
        />
        <button
          type="submit"
          className="py-2 px-4 w-full bg-highlight text-background text bold"
          to={"#"}
        >
          Absenden
        </button>
      </form>
      {response && (
        <p
          className={
            response.result === "error" ? "text-red-500" : "text-green-500"
          }
        >
          {response.msg}
        </p>
      )}
    </div>
  )
}

export default ContactForm
