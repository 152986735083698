import React from "react"
import Broschure from "./../assets/png/365BOX-Broschure-Download.png"
import BroschurePDF from "./../assets/pdf/365BOX.pdf"

const BroschureSection = () => {
  return (
      <div className="bg-background">
        <div className="flex flex-col md:flex-row gap-8 py-32 md:px-16 px-8 items-center justify-center max-w-screen-xl mx-auto">
          <div className="md:basis-1/2">
            <img alt="365BOX Broschüre Download" src={Broschure} />
          </div>
          <div className="md:basis-1/2 space-y-2">
            <h3 className="text-2xl">Alle Informationen zum Nachlesen</h3>
            <p className="text-l">Jetzt 365BOX Broschüre herunterladen</p>
            <a href={BroschurePDF} target="_blank" rel="noreferrer" className="py-2 px-4 bg-highlight mt-4 inline-block text-background text bold">
              Kostenlos herunterladen
            </a>
          </div>
        </div>
      </div>
  )
}

export default BroschureSection

