import React from "react"
import CheckIcon from "./../assets/svg/icon-checkmark.svg"

const InsideTheBoxSection = () => {
  return (
    <div className="bg-background flex justify-center py-12">
      <div className="bg-white p-8 space-y-4">
        <h2 className="text-primary font-bold text-xs">INSIDE THE BOX</h2>
        <ListItem
          main={"Personalisierbare News"}
          sub={
            "So individuell wie Ihre Mitarbeiter. Neuigkeiten nach Interessen, Standorten oder Abteilung"
          }
        />
        <ListItem
          main={"Mitarbeiterprofil"}
          sub={
            "Tageszeitabhängige Begrüßung und Absprung zum persönlichen Mitarbeiterprofil"
          }
        />
        <ListItem
          main={"Personalisierbare Linksammlung"}
          sub={"Produktiv Arbeiten durch personalisierbare Schnellzugriffe"}
        />
        <ListItem
          main={"Charts"}
          sub={"Kennzahlen prominent und Ansprechend im Intranet platzieren"}
        />
        <ListItem
          main={"Social Media"}
          sub={
            "Alle Social Media Kanäle auf einen Blick. Machen Sie Mitarbeiter zu Followern"
          }
        />
        <ListItem
          main={"Call To Action"}
          sub={
            "Alle Social Media Kanäle auf einen Blick. Machen Sie Mitarbeiter zu Followern"
          }
        />
        <ListItem
          main={"Banner"}
          sub={
            "Saisonale oder Event bezogene Highlights prominent positionieren"
          }
        />
        <ListItem
          main={"Akkordion"}
          sub={
            "Bauen Sie FAQ Übersichten oder platzieren Sie Inhalte ohne die Seite mit Inhalt zu überladen"
          }
        />
        <ListItem
          main={"Informationen, Warnungen und Störmeldungen"}
          sub={
            "Informieren Sie Ihre Mitarbeiter über aktuelle Ereignisse und Wartungsarbeiten"
          }
        />
        <ListItem
          main={"Erklärpfade"}
          sub={
            "Interaktive konfigurierbare Touren machen Technologie begreifbar und steigern nachweisbar die Aktzeptanz"
          }
        />
      </div>
    </div>
  )
}

export default InsideTheBoxSection

function ListItem({ main, sub }) {
  return (
    <div className="flex gap-2 items-center ">
      <div className="bg-primary p-2 flex-shrink-0 rounded-full  flex justify-center items-center">
        <img className="w-4 h-4" alt="" src={CheckIcon} />
      </div>
      <div>
        <h2 className="text-xl">{main}</h2>
        <p className="text-xs">{sub}</p>
      </div>
    </div>
  )
}
